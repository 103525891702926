/*table style */
.table tbody tr:hover td,
.table tbody tr:hover th,
.table tbody tr:hover td button.delete {
    background-color: var(--color-4);
}

.table-container {
    padding: '20px';
    width: 99%; 
    margin: 0 auto; 
    text-align: right;
  }

.table {
    border-collapse: collapse;
    border: 1px solid #000;
    border-radius: 10px;
}

.table th,
.table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid white;
    transition: 0.3s ease;
}
.table th {
    background-color: #197278;
    color: #fff;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f5f5f5;
}
.edit, .delete{
    border-width: 0px;
    text-decoration:underline;
    margin-right: 5px;
}


.edit:hover, .delete:hover{
    color: white
}

.btn-primary{
    border: var(--color-1);
    background-color:var(--color-1);
}
.btn-primary:hover{
    background-color: var(--color-4);
}
.table-header{
    padding-left: 10px;
    padding-top: 20px;
}

