.card-title {
    color: var(--color-5);
}
.card-body {
    border-radius: 20px;
    background-color:  var(--color-1);
    color: var(--color-5);
}
.card {
    border-radius: 20px;
    box-shadow: 0 4px 8px var(--color-2);
    border: 2px solid var(--color-5);
    transition: 0.3s ease;
    margin-top: 30px;
    cursor: pointer;
}
.account-card:hover {
    border-color: var(--color-4);
    transform: scale(1.05);
}

.plus {
    background: var(--color-5);
    height: 50px;
    position: relative;
    width: 4px;
    margin: 10px 31px;
}

.plus:after {
    background: var(--color-5);
    content: "";
    height: 4px;
    left: -24px;
    position: absolute;
    top: 22px;
    width: 50px;
}

.outline {
    border: 2px solid var(--color-1);
    width: 70px;
    border-radius: 100px;
}
.delete {
    background-color: var(--color-1);
    color: var(--color-5);
}
.delete:hover{
    background-color: var(--color-4)
}
.btn-success{
    border: var(--color-1);
    background-color: var(--color-1);
}
.btn-success:hover{
    background-color: var(--color-4);
}
body{
    background-color: var(--color-5);
}
h5{
    font-weight: 700;
}

