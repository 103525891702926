
/*.spending-chart {
    border-radius: 20px;
    border: 4px solid white;
    transition: 0.3s ease;
    margin-top: 30px;
    max-width: 500px; 
    position: absolute;
    top: 0px;
    right: 120px;
}*/



.graph-card{
    position:absolute;
    top:190px;
    left: 200px;
}
.spending-info{
    position:absolute;
    top:300px;
}
.spending-card{
    border-radius: 20px;
    border: 4px solid white;
    transition: 0.3s ease;
    margin-top: 30px;
    top: 180px;
    right: 200px;
    position:absolute
}

.spending-chart{
    color: var(--color-4);
}
