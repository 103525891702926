a:hover {
    text-decoration:underline;
    cursor: pointer;
}
.dropdown-menu a:hover {
    color: var(--color-4); 
}
.nav-link{
    color: black;
}
.button:hover{
    background-color:var(--color-4);
}
.custom-title{
    font-size: 50pt;
    font-weight: bold;
    color: var(--color-1);
    margin-bottom: 0px;
}
.nav-tabs{
    background-color: white;
    font-size: 16pt;
}
.nav-tabs:hover{
    color: var(--color-4);
}
.home-tab:hover,
.home-tab.active,
.home-tab:active{
    color: var(--color-4);
}
.budget-tab:hover{
    color: var(--color-4);
}
.account-tab:hover,
.account-tab.active
.account-tab:active{
    color: var(--color-4);
}
.nav-item dropdown:hover{
    color: var(--color-4)
}
.header{
    background-color: white;
    margin-bottom: 0px;
    padding: 0px;
}