.budget-card{
    border-radius: 20px;
    box-shadow: var(--color-3);
    border-color: var(--color-2);
    border-width: 2px;
    transition: 0.3s ease;
    height: 900px;
}


.budget-table thead th{
    background-color: var(--color-2);
}

.budget-table{
    border-radius: 20px;
    border: 2px solid rgb(21, 10, 10);
    transition: 0.3s ease;
    margin-top: 30px;
    max-width: 500px; 
    position: absolute;
    top: 100px;
    right: 120px;
}

.budget-body{
    background-color: var(--color-1);
}

h4{
    position:absolute;
    top:40px;
    right:280px
}
